import * as React from 'react'
import Seo from '../components/seo'
import Article from '../components/article'

const Privacy = () => {
  return (
    <>
      <Seo
        pageDescription={'Privacy Policy'}
        pageTitle={
          'Will Centre understands that your privacy is important to you and that you care about how your personal data is used.'
        }
      />
      <Article>
        <h1>Privacy Policy</h1>
        <p>
          Will Centre understands that your privacy is important to you and that
          you care about how your personal data is used. We respect and value
          the privacy of everyone who visits this website, www.willcentre.co.uk
          (“Our Site”) and will only collect and use personal data in ways that
          are described here, and in a way that is consistent with our
          obligations and your rights under the law.
        </p>
        <p>
          Please read this Privacy Policy carefully and ensure that you
          understand it.{' '}
        </p>
        <ol>
          <li>
            <strong>Definitions and Interpretation</strong>
            <p>
              In this Policy the following terms shall have the following
              meanings:
            </p>
            <ul>
              <li>
                <strong>Account</strong> means an account required to access
                and/or use certain areas and features of Our Site;
              </li>
              <li>
                <strong>Cookie</strong> means a small text file placed on your
                computer or device by Our Site when you visit certain parts of
                Our Site and/or when you use certain features of Our Site.
                Details of the Cookies used by Our Site are set out in Part 14,
                below; and
              </li>
              <li>
                <strong>Cookie Law</strong> means the relevant parts of the
                Privacy and Electronic Communications (EC Directive) Regulations
                2003;
              </li>
            </ul>
          </li>
          <li>
            <strong>Information About Us</strong>
            <p>
              Our Site is owned and operated by Will Centre, a trading style of
              Will & Estate Planning Centre Limited registered in England under
              company number 11888978. Registered address: 94 New Walk, 2nd
              Floor, Leicester, LE1 7EA. Email address:
              enquiries@willcentre.co.uk. Telephone number: 0330 010 0919.
            </p>
          </li>
          <li>
            <strong>What Does This Policy Cover?</strong>
            <p>
              This Privacy Policy applies only to your use of Our Site. Our Site
              may contain links to other websites. Please note that we have no
              control over how your data is collected, stored, or used by other
              websites and we advise you to check the privacy policies of any
              such websites before providing any data to them.
            </p>
          </li>
          <li>
            <strong>What Is Personal Data?</strong>
            <p>
              Personal data is defined by the UK GDPR and the Data Protection
              Act 2018 (collectively, “the Data Protection Legislation”) as ‘any
              information relating to an identifiable person who can be directly
              or indirectly identified in particular by reference to an
              identifier’.
            </p>
            <p>
              Personal data is, in simpler terms, any information about you that
              enables you to be identified. Personal data covers obvious
              information such as your name and contact details, but it also
              covers less obvious information such as identification numbers,
              electronic location data, and other online identifiers.
            </p>
          </li>
          <li>
            <strong>What Are My Rights?</strong>
            <p>
              Under the Data Protection Legislation, you have the following
              rights, which we will always work to uphold:
            </p>
            <ol type='a'>
              <li>
                The right to be informed about our collection and use of your
                personal data. This Privacy Policy should tell you everything
                you need to know, but you can always contact us to find out more
                or to ask any questions using the details in Part 15.
              </li>
              <li>
                The right to access the personal data we hold about you. Part 13
                will tell you how to do this.
              </li>
              <li>
                The right to have your personal data rectified if any of your
                personal data held by us is inaccurate or incomplete. Please
                contact us using the details in Part 15 to find out more.
              </li>
              <li>
                The right to be forgotten, i.e. the right to ask us to delete or
                otherwise dispose of any of your personal data that we hold.
                Please contact us using the details in Part 15 to find out more.
              </li>
              <li>
                The right to restrict (i.e. prevent) the processing of your
                personal data.
              </li>
              <li>
                The right to object to us using your personal data for a
                particular purpose or purposes.
              </li>
              <li>
                The right to withdraw consent. This means that, if we are
                relying on your consent as the legal basis for using your
                personal data, you are free to withdraw that consent at any
                time.
              </li>
              <li>
                The right to data portability. This means that, if you have
                provided personal data to us directly, we are using it with your
                consent or for the performance of a contract, and that data is
                processed using automated means, you can ask us for a copy of
                that personal data to re-use with another service or business in
                many cases.
              </li>
              <li>
                Rights relating to automated decision-making and profiling. We
                do not use your personal data in this way.
              </li>
            </ol>
            <p>
              For more information about our use of your personal data or
              exercising your rights as outlined above, please contact us using
              the details provided in Part 15.
            </p>
            <p>
              It is important that your personal data is kept accurate and
              up-to-date. If any of the personal data we hold about you changes,
              please keep us informed as long as we have that data.
            </p>
            <p>
              Further information about your rights can also be obtained from
              the Information Commissioner’s Office or your local Citizens
              Advice Bureau.
            </p>
            <p>
              If you have any cause for complaint about our use of your personal
              data, you have the right to lodge a complaint with the Information
              Commissioner’s Office. We would welcome the opportunity to resolve
              your concerns ourselves, however, so please contact us first,
              using the details in Part 15.
            </p>
          </li>
          <li>
            <strong>What Data Do You Collect and How?</strong>
            <p>
              Depending upon your use of Our Site, we may collect and hold some
              or all of the personal and non-personal data set out in the table
              below, using the methods also set out in the table. Please also
              see Part 14 for more information about our use of Cookies and
              similar technologies. We do not collect any data relating to
              criminal convictions and/or offences.
            </p>
            <table className='table'>
              <thead>
                <tr>
                  <th>Data Collected</th>
                  <th>How We Collect the Data</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    Contact information including Full name, email address and
                    telephone numbers.
                  </td>
                  <td>Contact form</td>
                </tr>
                <tr>
                  <td>Technical information including IP Address</td>
                  <td>Service Providers</td>
                </tr>
              </tbody>
            </table>
          </li>
          <li>
            <strong>How Do You Use My Personal Data?</strong>
            <p>
              Under the Data Protection Legislation, we must always have a
              lawful basis for using personal data. The following describes how
              we may use your personal data, and our lawful bases for doing so:
            </p>
            <ul>
              <li>
                Personal Data – Will be used by our employees to contact you
                regarding your online query.
              </li>
              <li>
                IP Address / Other technical tracking – So we can improve our
                services offered on our website by viewing locations and other
                technical details that may help us.
              </li>
            </ul>
            <p>
              With your permission and/or where permitted by law, we may also
              use your personal data for marketing purposes, which may include
              contacting you by email or telephone or text message or post with
              information, news, and offers on our products and services. You
              will not be sent any unlawful marketing or spam. We will always
              work to fully protect your rights and comply with our obligations
              under the Data Protection Legislation and the Privacy and
              Electronic Communications (EC Directive) Regulations 2003, and you
              will always have the opportunity to opt-out. We will always obtain
              your express opt-in consent before sharing your personal data with
              third parties for marketing purposes and you will be able to
              opt-out at any time.
            </p>
            <p>
              We will only use your personal data for the purpose(s) for which
              it was originally collected unless we reasonably believe that
              another purpose is compatible with that or those original
              purpose(s) and need to use your personal data for that purpose. If
              we do use your personal data in this way and you wish us to
              explain how the new purpose is compatible with the original,
              please contact us using the details in Part 15.
            </p>
            <p>
              If we need to use your personal data for a purpose that is
              unrelated to, or incompatible with, the purpose(s) for which it
              was originally collected, we will inform you and explain the legal
              basis which allows us to do so.
            </p>
            <p>
              In some circumstances, where permitted or required by law, we may
              process your personal data without your knowledge or consent. This
              will only be done within the bounds of the Data Protection
              Legislation and your legal rights.
            </p>
          </li>
          <li>
            <strong>How Long Will You Keep My Personal Data?</strong>
            <p>
              We will not keep your personal data for any longer than is
              necessary in light of the reason(s) for which it was first
              collected. Your personal data will therefore be kept for the
              following periods (or, where there is no fixed period, the
              following factors will be used to determine how long it is kept):
            </p>
            <table className='table'>
              <thead>
                <tr>
                  <th>Type of Data</th>
                  <th>How Long</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    Contact information including Full name, email address and
                    telephone numbers
                  </td>
                  <td>Until we have resolved your query</td>
                </tr>
                <tr>
                  <td>IP Addresses</td>
                  <td>Indefinitely</td>
                </tr>
              </tbody>
            </table>
          </li>
          <li>
            <strong>
              How and Where Do You Store or Transfer My Personal Data?
            </strong>
            <p>
              We will only store your personal data in the UK and EEA. This
              means that it will be fully protected under the Data Protection
              Legislation.
            </p>
            <p>
              The security of your personal data is essential to us, and to
              protect your data, we take a number of important measures,
              including the following:
            </p>
            <ul>
              <li>
                limiting access to your personal data to those employees,
                agents, contractors, and other third parties with a legitimate
                need to know and ensuring that they are subject to duties of
                confidentiality;
              </li>
              <li>
                procedures for dealing with data breaches (the accidental or
                unlawful destruction, loss, alteration, unauthorised disclosure
                of, or access to, your personal data) including notifying you
                and/or the Information Commissioner’s Office where we are
                legally required to do so.
              </li>
            </ul>
          </li>
          <li>
            <strong>Do You Share My Personal Data?</strong>
            <p>
              We will not share any of your personal data with any third parties
              for any purposes, subject to the following exceptions.
            </p>
            <p>
              If we sell, transfer, or merge parts of our business or assets,
              your personal data may be transferred to a third party. Any new
              owner of our business may continue to use your personal data in
              the same way(s) that we have used it, as specified in this Privacy
              Policy.
            </p>
            <p>
              In some limited circumstances, we may be legally required to share
              certain personal data, which might include yours, if we are
              involved in legal proceedings or complying with legal obligations,
              a court order, or the instructions of a government authority.
            </p>
          </li>
          <li>
            <strong>How Can I Control My Personal Data?</strong>
            <ol>
              <li>
                In addition to your rights under the Data Protection
                Legislation, set out in Part 5, when you submit personal data
                via Our Site, you may be given options to restrict our use of
                your personal data. In particular, we aim to give you strong
                controls on our use of your data for direct marketing purposes
                (including the ability to opt-out of receiving emails from us
                which you may do by unsubscribing using the links provided in
                our emails and at the point of providing your details and by
                managing your Account).
              </li>
              <li>
                You may also wish to sign up to one or more of the preference
                services operating in the UK: The Telephone Preference Service
                (“the TPS”), the Corporate Telephone Preference Service (“the
                CTPS”), and the Mailing Preference Service (“the MPS”). These
                may help to prevent you receiving unsolicited marketing. Please
                note, however, that these services will not prevent you from
                receiving marketing communications that you have consented to
                receiving.
              </li>
            </ol>
          </li>
          <li>
            <strong>Can I Withhold Information?</strong>
            <p>
              You may access certain areas of Our Site without providing any
              personal data at all. However, to use all features and functions
              available on Our Site you may be required to submit or allow for
              the collection of certain data.
            </p>
            <p>
              You may restrict our use of Cookies. For more information, see
              Part 14.
            </p>
          </li>
          <li>
            <strong>How Can I Access My Personal Data?</strong>
            <p>
              If you want to know what personal data we have about you, you can
              ask us for details of that personal data and for a copy of it
              (where any such personal data is held). This is known as a
              “subject access request”.
            </p>
            <p>
              All subject access requests should be made in writing and sent to
              the email or postal addresses shown in Part 15.{' '}
            </p>
            <p>
              There is not normally any charge for a subject access request. If
              your request is ‘manifestly unfounded or excessive’ (for example,
              if you make repetitive requests) a fee may be charged to cover our
              administrative costs in responding.
            </p>
            <p>
              We will respond to your subject access request within 28 working
              days and, in any case, not more than one month of receiving it.
              Normally, we aim to provide a complete response, including a copy
              of your personal data within that time. In some cases, however,
              particularly if your request is more complex, more time may be
              required up to a maximum of three months from the date we receive
              your request. You will be kept fully informed of our progress.
            </p>
          </li>
          <li>
            <strong>How Do You Use Cookies?</strong>
            <p>
              Our Site may place and access certain first-party Cookies on your
              computer or device. First-party Cookies are those placed directly
              by us and are used only by us. We use Cookies to facilitate and
              improve your experience of Our Site and to provide and improve our
              products and services. We have carefully chosen these Cookies and
              have taken steps to ensure that your privacy and personal data is
              protected and respected at all times.
            </p>
            <p>
              By using Our Site, you may also receive certain third-party
              Cookies on your computer or device. Third-party Cookies are those
              placed by websites, services, and/or parties other than us.
              Third-party Cookies are used on Our Site to measure
              user-interactions on websites. For more details, please refer to
              the table below. These Cookies are not integral to the functioning
              of Our Site and your use and experience of Our Site will not be
              impaired by refusing consent to them.
            </p>
            <p>
              All Cookies used by and on Our Site are used in accordance with
              current Cookie Law.
            </p>
            <p>
              Before Cookies are placed on your computer or device, you will be
              shown a pop-up requesting your consent to set those Cookies. By
              giving your consent to the placing of Cookies you are enabling us
              to provide the best possible experience and service to you. You
              may, if you wish, deny consent to the placing of Cookies; however
              certain features of Our Site may not function fully or as
              intended.{' '}
            </p>
            <p>
              the following third-party Cookies may be placed on your computer
              or device:
            </p>
            <table className='table'>
              <thead>
                <tr>
                  <th>Name of Cookie</th>
                  <th>Provider</th>
                  <th>Purpose</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>_ga</td>
                  <td>Google</td>
                  <td>Analytics</td>
                </tr>
                <tr>
                  <td>_gid</td>
                  <td>Google</td>
                  <td>Analytics</td>
                </tr>
                <tr>
                  <td>_gat_gtag_UA_138594568_1</td>
                  <td>Google</td>
                  <td>Analytics</td>
                </tr>
              </tbody>
            </table>
            <p>
              Our Site uses analytics services provided by Google. Website
              analytics refers to a set of tools used to collect and analyse
              anonymous usage information, enabling us to better understand how
              Our Site is used. This, in turn, enables us to improve Our Site
              and the products and services offered through it.
            </p>
            <p>
              The analytics service(s) used by Our Site use(s) Cookies to gather
              the required information. You do not have to allow us to use these
              Cookies, however whilst our use of them does not pose any risk to
              your privacy or your safe use of Our Site, it does enable us to
              continually improve Our Site, making it a better and more useful
              experience for you.
            </p>
            <p>
              The analytics service(s) used by Our Site use(s) the following
              Cookies:
            </p>
            <table className='table'>
              <thead>
                <tr>
                  <th>Name of Cookie</th>
                  <th>First / Third Party</th>
                  <th>Provider</th>
                  <th>Purpose</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>_ga</td>
                  <td>Third</td>
                  <td>Google</td>
                  <td>
                    This cookie is installed by Google Analytics. The cookie is
                    used to calculate visitor, session, campaign data and keep
                    track of site usage for the site's analytics report. The
                    cookies store information anonymously and assign a randomly
                    generated number to identify unique visitors.
                  </td>
                </tr>
                <tr>
                  <td>_gid</td>
                  <td>Third</td>
                  <td>Google</td>
                  <td>
                    This cookie is installed by Google Analytics. The cookie is
                    used to store information of how visitors use a website and
                    helps in creating an analytics report of how the website is
                    doing. The data collected including the number visitors, the
                    source where they have come from, and the pages visited in
                    an anonymous form.
                  </td>
                </tr>
                <tr>
                  <td>_gat_gtag_UA_138594568_1</td>
                  <td>Third</td>
                  <td>Google</td>
                  <td>
                    This cookie is installed by Google Analytics. The cookie is
                    used to store information of how visitors use a website and
                    helps in creating an analytics report of how the website is
                    doing. The data collected including the number visitors, the
                    source where they have come from, and the pages visited in
                    an anonymous form.
                  </td>
                </tr>
              </tbody>
            </table>
            <p>
              In addition to the controls that we provide, you can choose to
              enable or disable Cookies in your internet browser. Most internet
              browsers also enable you to choose whether you wish to disable all
              Cookies or only third-party Cookies. By default, most internet
              browsers accept Cookies, but this can be changed. For further
              details, please consult the help menu in your internet browser or
              the documentation that came with your device.
            </p>
            <p>
              You can choose to delete Cookies on your computer or device at any
              time, however you may lose any information that enables you to
              access Our Site more quickly and efficiently including, but not
              limited to, login and personalisation settings.
            </p>
            <p>
              It is recommended that you keep your internet browser and
              operating system up-to-date and that you consult the help and
              guidance provided by the developer of your internet browser and
              manufacturer of your computer or device if you are unsure about
              adjusting your privacy settings.
            </p>
          </li>
          <li>
            <strong>How Do I Contact You?</strong>
            <p>
              To contact us about anything to do with your personal data and
              data protection, including to make a subject access request,
              please use the following details:
            </p>
            <ul>
              <li>Email address: enquiries@willcentre.co.uk</li>
              <li>Telephone number: 0330 010 0919</li>
              <li>
                Postal Address: 94 New Walk, 2nd Floor, Leicester, LE1 7EA
              </li>
            </ul>
          </li>
          <li>
            <strong>Changes to this Privacy Policy</strong>
            <p>
              We may change this Privacy Notice from time to time. This may be
              necessary, for example, if the law changes, or if we change our
              business in a way that affects personal data protection.
            </p>
            <p>
              Any changes will be immediately posted on Our Site and you will be
              deemed to have accepted the terms of the Privacy Policy on your
              first use of Our Site following the alterations. We recommend that
              you check this page regularly to keep up-to-date. This Privacy
              Policy was last updated on 4th June 2021.
            </p>
          </li>
        </ol>
      </Article>
    </>
  )
}

export default Privacy
